import { useDateFormatter, useTranslations } from "../../contexts/Translation";
import { H2, H3 } from "../Headers";
import Section from "../Section";
import SurveyShortView from "./ShortView";
import Responses from "./Responses";
import { useSurvey } from "../../contexts/Survey";
import SystemMessage from '../../components/SystemMessage/Index';
import { useState } from "react";
import SurveyForm from "../Forms/SurveyForm";
import { Edit } from "../Icons";
import { IconButton } from "../Button";
import { useProfile } from "../../contexts/UserProvider";
import Invites from "./Invites";

const SurveyDetails = ({ isShared = false }) => {
  const t = useTranslations('surveys');
  const d = useDateFormatter();
  const survey = useSurvey();
  const profile = useProfile();
  const [isEdit, setIsEdit] = useState(false);

  if (!survey) {
    return <SystemMessage message="loading" />;
  }
  return (
      <Section>
        <H2>{survey.surveyName}</H2>
        <H3>{t('surveyHeading', d(survey.startDate), d(survey.endDate))} - {survey.candidate}
          { survey.userId === profile?.userId && <IconButton sx={{ border: 'none', color: isEdit ? 'primary' : 'mainText'}} onClick={() => setIsEdit(!isEdit)}><Edit /></IconButton> }
        </H3>
        { isEdit ? <SurveyForm isEdit />
        : <>
            <SurveyShortView {...survey} sx={{ px: 0 }} />
            { survey.status !== 'upcoming' && <Responses isShared={isShared} status={survey.status} surveyId={survey.id} candidate={survey.candidate} /> }
            { !isShared && survey.status === 'upcoming' && <Invites surveyId={survey.id} /> }
          </>}
      </Section>
  );
};

export default SurveyDetails;
