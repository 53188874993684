import { useEffect, useRef, useState } from 'react';
import { Box, Button, Image, Flex, Link } from 'theme-ui';
import LanguageSelector from '../LanguageSelector/Index';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslations } from '../../contexts/Translation';
import { Login } from '../Icons';
import { useOrganizations } from '../../contexts/UserProvider';
import { useApiData } from '../../contexts/ApiData';
import { Profile } from '../../types';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, loginWithRedirect, logout } = useAuth0();
  const { organizations, currentOrganization } = useOrganizations();
  const t = useTranslations('menu');
  const menuRef = useRef();
  const { get } = useApiData();
  const [ profile, setProfile ] = useState<Profile | null>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const closeDropdown = (e: MouseEvent) => {
      // @ts-ignore
      if (!menuRef?.current?.contains(e.target)) {
          setIsOpen(false);
      }
    }
    document.body.addEventListener('click', closeDropdown)
    return () => document.body.removeEventListener('click', closeDropdown)
  }, []);

  useEffect(() => {
    const call = async () => {
      const response = await get('/profile');
      setProfile(response);
    }
    call();
  }, [get]);

  const org = organizations?.find(o => o.id === currentOrganization);

  const NavItem = ({ href, text, onClick = toggleMenu, sx }: any) => (
    <Link sx={{ mt: 8, pl: 16, textDecoration: 'none', fontFamily: 'interaction', ':hover': { textDecoration: 'underline' }, color: 'mainText', display: 'block', textAlign: 'left', fontWeight: '400', ...sx }} href={href} p={2} onClick={onClick}>{text}</Link>
  );

  const isOwner = () => {
    const currentOrg = profile?.roles.find(o => o.orgId === currentOrganization);
    return currentOrg?.role === 'owner';
  };

  return (
    <Box ref={menuRef}>
      <Flex sx={{ justifyContent: 'right', mr: 0 }}>
        { org && (
        <Box sx={{  mr: 16, display: 'flex', border: '1px solid #ddd', backgroundColor: 'white', p: 4, borderColor: 'outline', borderRadius: '8px' }}>
          <img height={42} style={{ display: 'flex', }} src={org.logo} alt={org.name} />
        </Box>
        )}
        <Button onClick={toggleMenu} sx={{ color: isOpen ? 'background': 'mainText', fontSize: 40, background: 'transparent', cursor: 'pointer' }}>
        { user ? <Image sx={{ mr: 16, display: 'flex', borderRadius: '50%' }} src={`${user.picture}`} alt={`${user.name}`} height={50} width={50} />
        : <>☰</> }
        </Button>
      </Flex>
      {isOpen && (
        <Flex sx={{
          zIndex: 200,
          fontFamily: 'interaction',
          flexDirection: 'column', position: 'absolute',
          backgroundColor: 'background',
          border: '1px solid', borderColor: 'muted', borderRadius: '16px',
          boxShadow: '7px 10px 14px 3px rgba(0,0,0,0.26)',
          p: 16, width: '300px' }}>
          <Box sx={{ mb: 16, textAlign: 'center', justifyContent: 'center' }}>
            { user ? 
              <>
                <Box sx={{ my: 16 }}>
                  <Image sx={{ display: 'flex', mx: 'auto', borderRadius: '50%' }} src={`${user.picture}`} alt={`${user.name}`} height={50} width={50} />
                  <Box sx={{ my: 8 }}>{profile?.candidate}</Box>
                  <Box sx={{ fontSize: 14 }}>{user.name}</Box>
                </Box>
                { org && <Box sx={{ mt: 32 }}><img width={150} height={75} style={{ display: 'block', margin: '0 auto' }} src={org.logo} alt={org.name} /></Box> }
                {/* {
                  organizations ? <Select sx={{ width: 180, mt: 16, }} onChange={updateOrg} value={currentOrganization}>
                    {organizations.map((o: Organization) => {
                      return <option key={o.id} value={o.id}>{o.name}</option>
                    })}
                  </Select>
                  : null
                } */}
              </>
              : 
              <Button onClick={() => loginWithRedirect()}>
                <Login /> Login
              </Button>
            }
            <Box sx={{ mt: 32, px: 32, }}>
              <LanguageSelector lsx={{ display: 'block', width: '100%' }} label={t('misc.languageSelectorLabel')} name="languageSelector" />
            </Box>
          </Box>
          <Box>
            <NavItem href="/settings" text={t('settings')} />
            <NavItem sx={{ pl: 32 }} href="/settings#0" text={t('settings.profile')} />
            { currentOrganization && isOwner() && (
              <>
                <NavItem sx={{ pl: 32 }} href="/settings#1" text={t('settings.organization')} />
                <NavItem sx={{ pl: 32 }} href="/settings#2" text={t('settings.members')} />
                <NavItem sx={{ pl: 32 }} href="/settings#3" text={t('subscription.settingsHeading')} />
              </>
            )}
            <hr />
            <NavItem href="#" text={t('logout')} onClick={() => logout({ logoutParams: { returnTo: 'https://feedbach.me' } })} />
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default HamburgerMenu;
