import { Box } from "theme-ui";
import { Group } from "../../../types";
import { H3 } from "../../../components/Headers";
import { useTranslations } from "../../../contexts/Translation";
import { useEffect, useState } from "react";
import { useOrganizations } from "../../../contexts/UserProvider";
import { useApiData } from "../../../contexts/ApiData";

type ComponentProps = {
  groups: Group[];
  selected?: Group[];
  userId?: string;
  onChange: (selectedGroups: Group[], added: Group[], deleted: Group[]) => void;
};

const GroupSelector = ({ groups, userId, selected = [], onChange }: ComponentProps) => {
  const t = useTranslations('userManagementForm');
  const [groupList, setGroupList] = useState<Group[]>(groups.filter(g => !selected.find(s => s.id === g.id)));
  const [initiallySelected, setInitiallySelected] = useState<Group[]>(selected);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>(selected);
  const { currentOrganization } = useOrganizations();
  const { get } = useApiData();

  useEffect(() => {
    const getGroupsForUser = async () => {
      const groupResult = await get(`/organization/${currentOrganization}/user/${userId}/groups`);
      if (groupResult) {
        const mappedResult = groupResult.map((g: Group) => groups?.find(gr => gr.id === g.id) || {});
        setInitiallySelected(mappedResult);
        setSelectedGroups(mappedResult);
        setGroupList(groups.filter((g: Group) => !groupResult.find(({ id }: { id: string }) => id === g.id)));
      }
    };
    if (userId ){
      getGroupsForUser();
    }
  }, [currentOrganization, get, groups, userId]);

  const addToSelected = (group: Group) => {
    const sg = [...selectedGroups, group];
    const added: Group[] = sg.filter(g => !initiallySelected.find(s => s.id === g.id));
    const deleted: Group[] = initiallySelected.filter(s => !sg.find(g => g.id === s.id));
    setSelectedGroups(sg);
    setGroupList(groupList.filter(g => g.id !== group.id));
    onChange(sg, added, deleted);
  };
  const removeFromSelected = (group: Group) => {
    const sg = selectedGroups.filter(g => g.id !== group.id);
    const added: Group[] = sg.filter(g => !initiallySelected.find(s => s.id === g.id));
    const deleted: Group[] = initiallySelected.filter(s => !sg.find(g => g.id === s.id));
    
    setGroupList([...groupList, group]);
    setSelectedGroups(sg);
    onChange(sg, added, deleted);
  };

  return (
    <Box>
      <H3>{t('groupsHeading')}</H3>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <Box sx={{ mr: 32 }}>
        <H3>{t( userId ? 'memberOf' : 'accessFor')}</H3>
        { selectedGroups.map((g) => (
          <Box sx={{ cursor: 'pointer' }} onClick={() => removeFromSelected(g)} key={g.id}>{g.name}</Box>
        ))}
        </Box>
        <Box>
        <H3>{t('availableGroups')}</H3>
        { groupList.map((g) => (
          <Box sx={{ cursor: 'pointer' }} onClick={() => addToSelected(g)} key={g.id}>{g.name}</Box>
        ))}
        </Box>
      </Box>
    </Box>
  );
};

export default GroupSelector;