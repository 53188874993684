import { ReactNode, useState, useEffect } from 'react';
import { Box, Link } from 'theme-ui';
import Button from '../Button';
import { Left, Right } from '../Icons';

type HorizontalNavigationProps = {
  pages: ReactNode[],
  labels?: string[],
  start?: number;
}

const HorizontalNavigation = ({ pages, labels, start = 0 }: HorizontalNavigationProps) => {

  const getInitialIndex = () => {
    const hash = window.location.hash.replace('#', ''); // Remove the '#' from the hash
    const indexFromHash = parseInt(hash, 10);

    if (!isNaN(indexFromHash) && indexFromHash >= 0 && indexFromHash < pages.length) {
      return indexFromHash;
    }

    return start;
  };

  const [currentIndex, setCurrentIndex] = useState(getInitialIndex);

  useEffect(() => {
    window.location.hash = currentIndex === start ? '' : `#${currentIndex}`;
  }, [currentIndex, start]);

  useEffect(() => {
    const onHashChange = () => {
      const newIndex = getInitialIndex();
      if (newIndex !== currentIndex) {
        setCurrentIndex(newIndex);
      }
    };

    window.addEventListener('hashchange', onHashChange);

    return () => window.removeEventListener('hashchange', onHashChange);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, pages.length]);

  const goLeft = () => {
    const nextIndex = currentIndex === 0 ? pages.length - 1 : currentIndex - 1;
    setCurrentIndex(nextIndex);
  };

  const goRight = () => {
    const nextIndex = currentIndex === pages.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  const goToIndex = (index: number) => {
    return (e: React.FormEvent) => {
      e.preventDefault();
      setCurrentIndex(index);
    };
  };

  return (
    <Box>
      {pages.length > 1 && <>
        <Button sx={{ backgroundColor: 'muted', p: 8, mr: 16 }} onClick={goLeft}><Left /></Button>
          {pages.length > 10 ? `${currentIndex + 1} / ${pages.length}`
          : pages.map((_, index) => (
            <Box key={index} sx={{
              display: 'inline-block',
              textDecoration: index === currentIndex ? 'underline' : 'none',
              px: 8,
            }}>
              <Link sx={{ cursor: 'pointer' }} onClick={goToIndex(index)}>{labels && labels[index] ? labels[index] : index}</Link>
            </Box>
          ))}
        <Button sx={{ backgroundColor: 'muted', p: 8, ml: 16 }} onClick={goRight}><Right /></Button>
      </>}
      {pages[currentIndex] || null}
    </Box>
  );
};

export default HorizontalNavigation;
