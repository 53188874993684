import { getCookie, setCookie } from "../../lib/cookie";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect, useState } from "react";
import Main from "../Main";
import { Box } from "theme-ui";
import LogoBlack from '../Icons/Logo';
import Section from "../Section";
import { useTranslations } from "../../contexts/Translation";
import { useApiData } from "../../contexts/ApiData";
import SystemMessage from '../SystemMessage/Index';
import Button from "../Button";

const getOrgIdFromPath = (path: string) => {
  const parts = path.split('/');
  return parts[parts.length -1];
};

export const InvitationHandler = ({ children }: { children: ReactNode }) => {
  const orgId = getOrgIdFromPath(window.location.pathname);
  setCookie('invitedBy', orgId);
  return <>{children}</>;
};

export const InvitationLoggedInHandler = () => {
  const { user, getAccessTokenSilently } = useAuth0(); // Inkluder getAccessTokenSilently
  const { get, post } = useApiData();
  const t = useTranslations('join');
  const invitedBy = getCookie('invitedBy');
  const [org, setOrg] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Tilstand for innsending
  const [error, setError] = useState<string | null>(null); // Tilstand for feil

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const response = await get(`/org/${invitedBy}`);
        setOrg(response);
      } catch (err) {
        setError(t('orgFetchError'));
      }
    };
    if (user && invitedBy) {
      fetchOrg();
    }
  }, [get, invitedBy, user, t]);

  if (!org && !error) {
    return <SystemMessage message="loading" />;
  }

  if (error) {
    return <SystemMessage message={error} />;
  }

  const joinFn = async () => {
    setIsSubmitting(true);
    setError(null);
    try {
      const response = await post(`/organization/${org.id}/users`);
      if (response) {
        await getAccessTokenSilently({ cacheMode: "off" });
        window.location.href = '/';
      } else {
        setError(t('joinFailed'));
      }
    } catch (err) {
      setError(t('joinFailed'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Main>
      <Box sx={{
        background: 'secondaryBackground',
        my: 0,
        boxShadow: '0 10px 75px #212',
        p: 8,
        textAlign: 'center',
      }}>
        <a href="https://feedbach.me">
          <LogoBlack height="50" width="135" fillColor="#212" />
        </a>
      </Box>
      <Section sx={{ mt: 96, textAlign: 'center' }}>
        {t('aboutTo', org.name)}
        {org.logo && (
          <Box sx={{ mt: 32 }}>
            <img 
              style={{ maxHeight: '100px', maxWidth: '150px' }} 
              src={org.logo} 
              alt={org.name} 
            /> 
          </Box>
        )}
        <Box>
          <Button 
            sx={{ mt: 96, py: 28, px: 48 }} 
            onClick={joinFn} 
            disabled={isSubmitting}
          >
            {isSubmitting ? t('joining') : t('join', org.name)}
          </Button>
        </Box>
        {error && (
          <Box sx={{ mt: 16, color: 'error' }}>
            {error}
          </Box>
        )}
      </Section>
    </Main>
  );
};
