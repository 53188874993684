import { H2 } from "../../../components/Headers";
import { Box } from "theme-ui";
import { useTranslations } from "../../../contexts/Translation";
import { InfoMessage } from "../../../components/InfoMessage/Index";
import { useUserManagement } from "./UserManagementContext";
import Button from "../../../components/Button";
import List from "../../../components/List/List";


type ComponentProps = {
  setShowGroups: (show: boolean) => void;
};

const GroupsSection = ({ setShowGroups }: ComponentProps ) => {
  const t = useTranslations('userManagementForm');
  const { groups } = useUserManagement();
  return (
    <Box>
      <H2>{t('groupsHeading')}</H2>
      {  groups.length === 0 && <InfoMessage>{t('noGroups')}</InfoMessage> }
      { groups.length > 0 && <List items={groups.map(d => <Box>{d.name}</Box>)} /> }
      <Button onClick={() => setShowGroups(true)}>{t(groups.length === 0 ? 'addGroup': 'editGroups')}</Button>      
    </Box>
  );
};

export default GroupsSection;