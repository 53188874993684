import { useTranslations } from "../../contexts/Translation";
import { useState } from 'react';
import { Box, Grid, Link } from "theme-ui";
import { useApiData } from "../../contexts/ApiData";
import { Group, ValidationError } from "../../types";
import List from "../List/List";
import Input from "../Forms/Input";
import { H2 } from "../Headers";
import Button, { IconButton } from "../Button";
import { useOrganizations } from "../../contexts/UserProvider";
import { DeleteItem, Undo } from "../Icons";
import { useUserManagement } from "./UserManagement/UserManagementContext";

type ComponentProps = {
  closeFn: () => void;
};

type ItemProps = Group & {
  toggleDeleted: (id: string, isDeleted: boolean) => void;
};

const GroupsListItem = ({name, id, toggleDeleted}: ItemProps) => {
  const [ isDeleted, setIsDeleted ] = useState(false);
  const deleteItem = () => {
      toggleDeleted(id || "", !isDeleted);
      setIsDeleted(!isDeleted);
  };

  return (
    <Box sx={{ textDecoration: isDeleted ? 'line-through': 'none' }}>{name}
      { !!id && <IconButton sx={{ border: 'none', color: 'muted'}} onClick={deleteItem}>{ isDeleted ? <Undo /> : <DeleteItem />}</IconButton> }
    </Box>
  );
};

const GroupForm = ({ closeFn }: ComponentProps) => {
  const t = useTranslations('userManagementForm');
  const { groups, setGroups } = useUserManagement();

  const { currentOrganization } = useOrganizations();
  const { post, del } = useApiData();
  const [newGroups, setNewGroups] = useState<Group[]>([{ id: '', name: '', org: currentOrganization }]);
  const [deletedGroups, setDeletedGroups] = useState<Group[]>([]);

  const [errors, setErrors] = useState<ValidationError[]>([]);

  const goBack = (e: any) => {
    e.preventDefault();
    closeFn();
  };

  const handleGroupChange = (index: number, value: string) => {
    const grps:Group[] = [...newGroups];

    grps[index] = {
      ...grps[index],
      name: value
    };

    if (index === grps.length - 1 && value) {
      grps.push({ id: '', name: '', org: currentOrganization });
    }
    setNewGroups(grps);
  };

  const handleBlur = (index: number) => {
    const { name } = newGroups[index];
    if (name.length < 1) {
      setErrors([...errors, { name: `group-${index}`, message: t('invalidGroup') }]);
    } else {
      setErrors(errors.filter(e => e.name !== `group-${index}`));
    }
  };

  const toggleDeleted = (id: string, isDeleted: boolean) => {
    const department = groups.find(d => d.id === id);
    if (department) {
      setDeletedGroups(isDeleted ? [...deletedGroups, department] : deletedGroups.filter(d => d.id !== id));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = newGroups.filter(({ name }) => name !== '');

    if (data.length > 0) {
      const result = await post(`/organization/${currentOrganization}/groups`, data);
      if (result) {
        setGroups([...groups, ...result]);
        setNewGroups([{ id: '', name: '', org: currentOrganization }]);
      } else {
        setErrors([{ name: 'form', message: t('misc.serverError') }]);
      }
    }
    if (deletedGroups.length > 0) {
      Promise.all(deletedGroups.map(async ({ id }) => {
        await del(`/organization/${currentOrganization}/groups/${id}`);
      })).then(() => {
        setGroups(groups.filter(d => !deletedGroups.find(dg => dg.id === d.id)));
        closeFn();
      });
    }
  };

  const closeLink = <Link sx={{ ml: '1rem', fontSize: 12, fontWeight: 'normal', cursor: 'pointer', textDecoration: 'underline' }} onClick={goBack}>{t('goBack')}</Link>;
  return (
    <Box as="form" onSubmit={handleSubmit}>
      <H2>{t(groups.length === 0 ? 'addGroup': 'editGroup')} {closeLink}</H2>
      <List items={groups.map((g: Group) => <GroupsListItem toggleDeleted={toggleDeleted} {...g} />)} />
      {newGroups.map(({ name }, index) => (
        <Grid key={index} mb={3} sx={{
          alignItems: 'end',
          gridTemplateColumns: '5fr 1fr',
          gap: 16,
          mb: 16
        }}>
          <Box sx={{
            '> div': { mb: 0 },
            'input': { mb: 0 },
          }}>
            <Input
              error={errors.find(e => e.name === `group-${index}`)}
              name={`group-${index}`}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleGroupChange(index, e.target.value)}
              handleBlur={() => handleBlur(index)}
              label={t('groupNameLabel')}
              value={name}
            />
          </Box>
        </Grid>
      ))}
      <Button>{t('submit')}</Button> {closeLink}
    </Box>
  );
};

export default GroupForm;