
import { Box } from 'theme-ui';
import { useTranslations } from '../../contexts/Translation';
import { H1 } from '../Headers';
import Section from '../Section';
import { useEffect, useState } from 'react';
import { useApiData } from '../../contexts/ApiData';
import { v4 as uuid } from 'uuid';
import Button from '../Button';
import Input, { Select } from './Input';
import { ImprovementGoal, ValidationError, ValidationResult } from '../../types';
import ErrorText from './ErrorText';
import { useNavigate } from 'react-router-dom';
import ImprovementSelector from './ImprovementSelector/ImprovementSelector';
import { useImprovementGoal } from '../../contexts/ImprovementGoal';
import GroupAccess from './GroupAccess';

type ComponentProps = {
  isEdit?: boolean;
  surveyId?: string | null;
}

const ImprovementGoalForm = ({ isEdit = false, surveyId = null, }: ComponentProps) => {
  const t = useTranslations('improvementGoals');
  const { goal, improvementData, originalSurvey } = useImprovementGoal();
  const { post, put } = useApiData();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const navigate = useNavigate();
  const initialData: ImprovementGoal = {
     id: goal?.id || uuid(),
     endDate: goal?.endDate || (new Date(Date.now() + 26*7*24*3600000)).toISOString().substring(0,10),
     categories: goal?.categories || [],
     goal: goal?.goal || '',
     name: goal?.name || '',
     plan: goal?.plan || '',
     result: goal?.result || '',
     situation: goal?.situation || '',
     originalSurvey: goal?.originalSurvey || surveyId || '',
     accessibleBy: goal?.accessibleBy || 'me',
     groupsWithAccess: goal?.groupsWithAccess || originalSurvey?.groupsWithAccess || [],
  };
  const [formData, setFormData] = useState<ImprovementGoal>(initialData);
  const [availableGroups, setAvailableGroups] = useState<string[]>([]);

  useEffect(() => {
    setAvailableGroups(originalSurvey?.groupsWithAccess || []);
  }, [originalSurvey]);

  const validateData = ({ name, endDate, originalSurvey }: ImprovementGoal): ValidationResult => {
    const errors = [];
    // const inOneWeek = (new Date(Date.now() + 7*24*3600000)).toISOString().substring(0, 10);

    if (!name) { errors.push({ name: 'name', message: t('fieldRequired')}) }

    if (!endDate) { errors.push({ name: 'endDate', message: t('fieldRequired')}) }
    if (!originalSurvey) { errors.push({ name: 'form', message: t('missingOriginalSurvey')}) }

    return { errors, success: errors.length === 0 };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setErrors(errors.filter(err => err.name !== e.target.name));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const updateCategories = (selected: string[]) => {
    setFormData({ ...formData, categories: selected});
  };

  const setGroupsWithAccess = (groups: string[]) => {
    setFormData(prevData => ({ ...prevData, groupsWithAccess: groups }));
    setErrors(errors.filter(error => error.name !== 'groupsWithAccess'));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateData(formData);

    if (validationResult.success) {
      const result = isEdit ? await put(`/improvement-goal/${formData.id}`, formData) : await post(`/improvement-goal/${formData.id}`, formData);
      if (!result) {
        setErrors([{ name: 'form', message: t('serverError') }]);
      } else {
        if (isEdit) {
          window.location.reload();
        } else {
          navigate(`/improvement/${formData.id}`)
        }
      }
    } else {
      setErrors(validationResult.errors || []);
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth',
      }); 
    }
  };


  const getAccessibleByOptions = () => {
    const originalAccess = originalSurvey?.accessibleBy;

    const accessOptions = [
      { value: 'me', text: t('accessibleByMe') },
      { value: 'supporters', text: t('accessibleBySupporters') },
      { value: 'selectedGroups', text: t('surveyForm.accessibleBySelectedGroups') },
      { value: 'org', text: t('accessibleByOrganization') },
    ];
    const originalOptionIdx = accessOptions.findIndex(o => o.value === originalAccess);
    const availableAccessOptions = accessOptions.filter((o, idx) => idx <= originalOptionIdx);
    return availableAccessOptions;
  };

  return (
    <Section>
      <H1>{isEdit ? t('editHeading') : t('heading')}</H1>
      <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      
        { errors.filter(e => e.name !== 'form').length > 0 && <ErrorText sx={{ mt: 32, fontSize: 24, }} text={t('formErrors')} /> }
        <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />

        <Input
          error={errors.find(e => e.name === 'name')}
          name="name"
          handleChange={handleChange}
          label={t('name')}
          value={formData.name} />

        { improvementData && <ImprovementSelector handleChange={updateCategories} selected={formData.categories} scores={improvementData} /> }

        <Input
          error={errors.find(e => e.name === 'endDate')}
          type="date"
          name="endDate"
          handleChange={handleChange}
          label={t('endDate')}
          value={formData.endDate} />
        
        <Input
          type="textarea"
          name="goal"
          handleChange={handleChange}
          label={t('goal')}
          value={formData.goal}
        />

        <Input
          type="textarea"
          name="situation"
          handleChange={handleChange}
          label={t('situation')}
          value={formData.situation}
        />

        <Input
          type="textarea"
          name="plan"
          handleChange={handleChange}
          label={t('plan')}
          value={formData.plan}
        />
        <Box sx={{ my: 16 }}>
          <Select
            options={getAccessibleByOptions()}
            handleChange={handleChange}
            name="accessibleBy"
            label={t('accessibleBy')}
            value={formData.accessibleBy}
          />
        </Box>
        {formData.accessibleBy === 'selectedGroups' && (
            <GroupAccess
              availableGroups={availableGroups}
              initiallySelected={formData.groupsWithAccess || []}
              setGroupsWithAccess={setGroupsWithAccess}
            />
          )}
        <Button>{t(isEdit ? 'submitEdit' : 'submit')}</Button>
      </Box>
    </Section>
  );
};

export default ImprovementGoalForm;
