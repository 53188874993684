import { useDateFormatter, useTranslations } from "../../contexts/Translation";
import { H1 } from "../Headers";
import Section from "../Section";
import SystemMessage from '../../components/SystemMessage/Index';
import { useState } from "react";
import { Edit } from "../Icons";
import { IconButton } from "../Button";
import { useProfile } from "../../contexts/UserProvider";
import { InfoItem } from "../Survey/InfoItem";
import HorizontalNavigation from "../HorizontalNavigation/Index";
import { useOrgGoal } from "../../contexts/OrganizationGoal";
import ConclusionFlag from "../ImprovementGoal/ConclusionFlag";
import { ExecutionPhaseComponent } from "../ImprovementGoal/ExecutionPhaseComponent";
import { ConclusionPhaseComponent } from "../ImprovementGoal/ConclusionPhaseComponent";
import { InitialPhaseComponent } from "../ImprovementGoal/InitialPhaseComponent";


const OrgGoalDetails = () => {
  const t = useTranslations('improvementGoals');
  const d = useDateFormatter();
  const { goal } = useOrgGoal();
  const profile = useProfile();
  const [isEdit, setIsEdit] = useState(false);

  if (!goal) {
    return <SystemMessage message="loading" />;
  }

  const labels = [t('phasesInitial'), t('phasesExec'), t('phasesConclusion')];
  const pages = [
    <InitialPhaseComponent isOrgGoal goal={goal} isEdit={isEdit} />,
    <ExecutionPhaseComponent isOrgGoal goal={goal} />,
    <ConclusionPhaseComponent isOrgGoal goal={goal} isEdit={isEdit} />
  ];

  const getStartPageForStatus = () => {
    if (goal.status === 'planning') { return 0; }
    if (goal.status === 'execution') { return 1; }
    return 2;
  };

  return (
      <Section>
        <H1>{ goal.name || t('goalHeading')} <ConclusionFlag isSuccess={goal.isSuccess} />
          { goal.userId === profile?.userId && <IconButton sx={{ border: 'none', color: isEdit ? 'primary' : 'mainText'}} onClick={() => setIsEdit(!isEdit)}><Edit /></IconButton> }
        </H1>
        
        { goal.registered && <InfoItem>{t('registeredLabel', d(goal.registered))}</InfoItem> }
        <InfoItem>{t('endDateLabel', d(goal.endDate))}</InfoItem>
        {/* <InfoItem><Link href={isShared ? `/shared/${goal.originalSurvey}`: `/survey/${goal.originalSurvey}`}>{t('originalSurvey')}</Link></InfoItem> */}
        <InfoItem>{t(`status_${goal.status}`)}</InfoItem>
        <HorizontalNavigation labels={labels} pages={pages} start={getStartPageForStatus()} />
      </Section>
  );
};

export default OrgGoalDetails;
