import { useImprovementGoal } from "../../contexts/ImprovementGoal";
import { ExecutionPhaseComponent } from "./ExecutionPhaseComponent";


const ExecutionPhase = () => {
  const { goal, improvementData, recentSurveys } = useImprovementGoal();

  if (!goal) {
    return null;
  }

  return (
    <ExecutionPhaseComponent goal={goal} improvementData={improvementData} recentSurveys={recentSurveys || []} />
  );
};

export default ExecutionPhase;
