import { ScoresObject } from "../../../types";
import { Box, Grid } from "theme-ui";
import { useTranslations } from "../../../contexts/Translation";
import EmScore from "../../../components/Survey/EmScore";

type ImpSelProps = {
  score: number;
  label: string;
  name: string;
  isSelected: boolean;
  toggleFn: (n: string, s: boolean) => void;
};

type ComponentProps = {
  scores: ScoresObject | undefined;
  selected: string[];
  handleChange: (s: string[]) => void;
};

const ImprovementSelectorItem = ({ score, label, name, isSelected, toggleFn }: ImpSelProps) => {
  return (
    <Box onClick={() => toggleFn(name, !isSelected)} sx={{
        ':hover': { borderColor: 'mainText' },
        cursor: 'pointer', p: 24,
        outline: '1px solid #bbb',
        outlineStyle: 'solid',
        outlineWidth: isSelected ? '3px': '1px',
        outlineColor: isSelected ? 'mainText': 'muted',
        fontSize: 32,
        borderRadius: 8 }}>
      <EmScore afterSx={{ bottom: '-1.2em' }} size={3} after={label} score={score} />
    </Box>
  );
};

const ImprovementSelector = ({ scores, selected, handleChange }: ComponentProps) => {
  const t = useTranslations('improvementGoals');
  
  if (!scores) {
    return null;
  }

  const onChange = (name: string, isSelected: boolean) => {
    if (isSelected) {
      handleChange([...selected, name]);
    } else {
      handleChange(selected.filter(s => s !== name));
    }
  };
  type IWProps = { name: string; }
  const ItemWrapper = ({ name }: IWProps) => {
    const isSelected = selected.includes(name);
    return (
      <ImprovementSelectorItem
        name={name}
        isSelected={isSelected}
        toggleFn={() => onChange(name, !isSelected)}
        label={t(`${name}Label`)}
        score={scores[name as keyof ScoresObject]}
      />
    );
  };

  return (
    <Box>
      <Box as="p" sx={{ pb: 8 }}>{t('categoriesLabel')}</Box>
      <Grid sx={{ justifyItems: 'center', gridTemplateColumns: 'repeat(4, 1fr)', gridTemplateRows: 'repeat(1, 1fr)' }}>
        {[ 'knowledge', 'knowledgeSharing', 'influence', 'collaboration' ].map(question => <ItemWrapper key={question} name={question} />)}
      </Grid>
    </Box>
  );
};

export default ImprovementSelector;
