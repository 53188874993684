import { Box, Grid } from "theme-ui";
import { ScoresObject } from "../../types";
import { useTranslations } from "../../contexts/Translation";
import EmScore from "../Survey/EmScore";

type ComponentProps = {
  scores: ScoresObject | undefined;
  selected: string[];
  status?: string;
  compareTo?: ScoresObject | null;
};

const QuestionScores = ({ scores, selected, status, compareTo = null }: ComponentProps) => {
  const t = useTranslations('improvementGoals');
  if (!scores) {
    return null;
  }
  return (
    <Box>
      <Grid sx={{ justifyItems: 'center', gridTemplateColumns: 'repeat(4, 1fr)', gridTemplateRows: 'repeat(1, 1fr)' }}>
        {scores && [ 'knowledge', 'knowledgeSharing', 'influence', 'collaboration' ].map(question => {
          const isSelected = selected.includes(question);
          let diff = 0;

          if (compareTo && compareTo[question as keyof ScoresObject]) {
            diff = scores[question as keyof ScoresObject] - compareTo[question as keyof ScoresObject];
          }
          return (<Box key={question} >
            <Box sx={{
              p: 24,
              outline: '1px solid #bbb',
              outlineStyle: 'solid',
              outlineWidth: isSelected ? '3px': '1px',
              outlineColor: isSelected ? 'mainText': 'muted',
              opacity: isSelected ? '1' : '.5',
              fontSize: 32,
              borderRadius: 8 }}>
              <EmScore afterSx={{ bottom: '-1.2em' }} size={3} status={status} score={scores[question as keyof ScoresObject]} after={t(`${question}Label`)} />
            </Box>
            { compareTo && compareTo[question as keyof ScoresObject] && (
              <Box sx={{
                fontFamily: 'interaction',
                fontWeight: 'bold',
                fontSize: 24,
                textAlign: 'center',
                color: diff === 0 || !isSelected ? 'unknown' : diff > 0 ? 'positive' : 'negative', 
                mt: 8 }}>
                  { compareTo[question as keyof ScoresObject] === 0 || scores[question as keyof ScoresObject] === 0 || isNaN(diff) ? '-' : 
                    <>
                      {diff.toFixed(1)} { diff === 0 ? '→' : diff > 0 ? '↗' : '↘'}
                    </>
                  }
              </Box>
            )}
          </Box>);
        })}
      </Grid>
    </Box>
  )
};

export default QuestionScores;
