
import { Box, Flex } from 'theme-ui';
import { useDateFormatter, useTranslations } from '../../contexts/Translation';
import { H2, H3 } from '../Headers';
import Section from '../Section';
import { useEffect, useState } from 'react';
import { useApiData } from '../../contexts/ApiData';
import { v4 as uuid } from 'uuid';
import Button from '../Button';
import { Survey, ValidationError, ValidationResult } from '../../types';
import ErrorText from './ErrorText';
import FeedbachCard from '../FeedbachCard/Index';
import List from '../List/List';
import { useNavigate } from 'react-router-dom';
import Input from './Input';
import { InfoMessage } from '../InfoMessage/Index';

const CardForm = () => {
  const t = useTranslations('cardForm');
  const d = useDateFormatter();
  const { post, get } = useApiData();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [selected, setSelected] = useState<Survey[]>([]);
  const [previewCardData, setPreviewCardData] = useState({});
  const [imageBase64, setImageBase64] = useState('');
  const [hasPreview, setHasPreview] = useState(false);  
  const navigate = useNavigate();
  const [cardTitle, setCardTitle] = useState('');
  const [cardId] = useState(uuid());

  useEffect(() => {
    const call = async () => {
      const surveyList = await get('/survey');
      setSurveys(surveyList);
      // setSurveys(surveyList1.filter((s: Survey) => s.status === 'finished'));
    }
    call();
  }, [get]);

  useEffect(() => {
    const call = async () => {
      const previewCard = await get(`/card/preview?from=${selected[0].id}&to=${selected[1].id}`);
      setPreviewCardData(previewCard);
      setHasPreview(true);

    }
    if (selected.length === 2) {
      call();
    }
  }, [get, selected])

  const validateData = (): ValidationResult => {
    const errors = [];

    if (selected.length !== 2) { errors.push({ name: 'form', message: t('invervalError')}) }

    return { errors: errors.length > 0 ? errors: undefined, success: errors.length === 0 };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCardTitle(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateData();

    if (validationResult.success) {
      const formData = {
        from: selected[0].id,
        to: selected[1].id,
        cardTitle,
        image: imageBase64,
      };
      const result = await post(`/card/${cardId}`, formData);
      if (!result) {
        setErrors([{ name: 'form', message: t('serverError') }]);
      } else {
        navigate(`/cards`);
      }
    } else {
      setErrors(validationResult.errors || []);
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth',
      }); 
    }
  };

  const SurveyListItem = ({ survey, isSelected, index = null }: any) => {
    const onClick = () => {
      setErrors([]);
      if (!isSelected) {
        if (selected.length >= 2) {
          return;
        }
        if (selected.length === 0) {
          setSelected([survey]);
          return;
        }
        setSelected([...selected, survey].sort((a, b) => a.endDate > b.endDate ? 1: -1)); // last...first
      } else {
        setSelected(selected.filter(s => s.id !== survey.id));
      }
    };

    return (
      <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
        { isSelected && <Box as="p" sx={{ fontWeight: 'bold' }}>{index === 0 ? t('startingSurvey') : t('endingSurvey')}</Box>}
        {survey.surveyName || t('survey')} {d(survey.startDate)}-{d(survey.endDate)}
      </Box>
    );
  };

  return (
    <Section>
      <H2>{t('heading')}</H2>
      { surveys && surveys.length === 0 ? (
        <InfoMessage>{t('noSurveys')}</InfoMessage>
      ): (
        <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
          { errors.filter(e => e.name !== 'form').length > 0 && <ErrorText sx={{ mt: 32, fontSize: 24, }} text={t('formErrors')} /> }
          <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />
          <Box as="p">
            {t('selectSurveyInfo')}
          </Box>
          <Flex sx={{ flexDirection: 'row', }}>
            <Box>
              <H3>{t('surveys')}</H3>
              { surveys && <List items={surveys.map(s => <SurveyListItem survey={s} />)} /> }
            </Box>
            <Box sx={{ ml: 48 }}>
              <H3>{t('selectedHeading')}</H3>
              {selected.length > 0 && <List items={selected.map((s, i) => <SurveyListItem survey={s} isSelected index={i} />)} /> }
            </Box>
          </Flex>
          <Input
            error={errors.find(e => e.name === 'cardTitle')}
            name="cardTitle"
            handleChange={handleChange}
            label={t('cardTitle')}
            value={cardTitle} />

          { hasPreview && (<>
            <H3>{t('preview')}</H3>
            <FeedbachCard setImage={setImageBase64} isPreview card={{ ...previewCardData, cardId, cardTitle }} />
          </>)}
          <Button>{t('submit')}</Button>
        </Box>
      )}
    </Section>
  );
};

export default CardForm;
