import type { Theme, ThemeUIStyleObject } from 'theme-ui';

type FeedBachTheme = Theme & {
  lists: Record<string, ThemeUIStyleObject>
};

export const theme: FeedBachTheme = {
  fonts: {
    body: 'Comfortaa, sans-serif',
    heading: 'PT Sans, sans-serif',
    interaction: 'Open Sans',
    monospace: 'monospace',
  },
  fontWeights: {
    normal: 400,
    strong: 600,
    bold: 700
  },
  fontSizes: {
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    20: '1.25rem',
    25: '1.563rem',
    31: '1.953rem',
    39: '2.441rem',
    48: '3.052rem',
    61: '3.815rem',
  },
  colors: {
    mainText: '#441144',
    mutedText: '#666',
    muted: '#ddd',
    outline: '#333',
    background: 'white',
    secondaryBackground: '#EDCDFF',
    tertiaryBackground: '#EDCDFF',// '#edcd4f',
    responderPrimary: '#7032a3',
    secondaryCta: '#fff7d0',
    borderColor: '#BF98BE',
    linkColor: '#7032a3',
    primary: '#EDCDFF',
    error: '#b20303',
    positive: '#079100',
    negative: '#d80d0d',
    mediocre: '#d8b60d',
    info: '#f9e393',
    unknown: '#777',
  },
  sizes: {
    4: '0.25rem',
    8: '0.5rem',
    12: '0.75rem',
    16: '1rem',
    18: '1.125',
    20: '1.25rem',
    container: 768
  },
  borders: {
    '1px': '1px solid',
    '2px': '2px solid',
  },
  space: {
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    32: '2rem',
    48: '3rem',
  },
  shadows: {
    primary: '3px 5px 14px -1px rgba(0,0,0,0.4)',
  },
  lists: {
    horizontal: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  // for markdown styling
  styles: {
    root: {
      fontFamily: 'body',
      fontSize: '20px',
    },
    h1: {
      fontSize: 61,
    },
    h2: {
      fontSize: 24,
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 25,
    },
    h6: {
      fontSize: 20,
    }
  },
  lineHeights: {
    body: 1.4,
  }
};
