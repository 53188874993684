import { getCookie, setCookie } from '../lib/cookie';
import React, { createContext, useContext, useState, ReactNode } from 'react';

type Translations = {
  [locale: string]: {
    [category: string]: {
      [key: string]: string;
    };
  };
};

const TranslationContext = createContext<{
  translations: Translations;
  locale: string;
  setLocale: (locale: string) => void;
}>({ translations: {}, locale: 'en', setLocale: () => { } });

export const useTranslations = (category: string) => {
  const { translations, locale } = useContext(TranslationContext);

  const t = (key: string, ...args: (string | number | undefined)[]) => {
    const parts = key.split('.');
    let translation = '';
    if (parts.length === 2) {
      translation = translations[locale]?.[parts[0]]?.[parts[1]] || `${category}.${key}`;
    } else {
      translation = translations[locale]?.[category]?.[key] || `${category}.${key}`;
    }

    args.forEach((arg, index) => {
      translation = translation.replace(new RegExp(`\\{${index + 1}\\}`, 'g'), `${arg}`);
    });

    return translation;
  };

  return t;
};

export const useDateFormatter = () => {
  const { locale } = useContext(TranslationContext);

  const dateFormatter = (d: Date | string | undefined | null, includeTime: boolean = false) => {
    if (!d) {
      return '';
    }
    const date = typeof d === 'string' ? new Date(d) : d;

    return (new Intl.DateTimeFormat([locale, 'no'], {
      dateStyle: 'short',
      timeStyle: includeTime ? 'short' : undefined,
    })).format(date);
  };
  return dateFormatter;
}

export const useSetLocale = () => {
  const { locale, setLocale } = useContext(TranslationContext);
  return { locale, setLocale };
};

export const useLocale = (): "en" | "no" => {
  const { locale } = useContext(TranslationContext);
  return locale === 'no' ? 'no' : 'en';
}

type TranslationProviderProps = {
  children: ReactNode;
  translations: Translations;
};

const getLang = (translations: Translations) => {
  const searchParams = new URLSearchParams(window.location.search);
  const lang = searchParams.get('lang');
  const gotLang = !!translations[lang || ''];
  if (gotLang) {
    setCookie('LANG', lang);
  }
  return gotLang ? lang : false;
};

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  children,
  translations,
}) => {
  const [locale, setLocale] = useState(getLang(translations) || getCookie('LANG') || 'no');

  return (
    <TranslationContext.Provider value={{ translations, locale, setLocale }}>
      {children}
    </TranslationContext.Provider>
  );
};
