import { useApiData } from '../../contexts/ApiData';
import { useTranslations } from '../../contexts/Translation';
import { useState, useEffect } from 'react';
import { Box } from 'theme-ui';
import { H1, H2 } from '../Headers';
import Section from '../Section';
import SystemMessage from '../SystemMessage/Index';
import { ResponderData, ValidationError, ValidationResult } from '../../types';
import Answer from '../Answer/Index';
import Input from './Input';
import Button from './Button';
import ErrorText from './ErrorText';
import Logo from '../Icons/Logo';
import Link from '../Link';

type ResponseFormData = {
  knowledge?: number | null;
  knowledgeSharing?: number | null;
  influence?: number | null;
  collaboration?: number | null;
  recommendation?: string;
  message?: string;
  duration: number;
  giveRecommendation?: number;
  recommendationName?: string;
  recommendationTitle?: string;
};

const ResponseForm = () => {
  const { get, post } = useApiData();
  const [responderData, setResponderData] = useState<ResponderData | null>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [startingTime, setStartingTime] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [isDone, setIsDone] = useState<boolean>(false);
  const t = useTranslations('responderForm');
  const id = window.location.pathname.split('/')?.[2];

  useEffect(() => {
    const call = async () => {
      const result = await get(`/respond/${id}`);
      if (result) {
        if (result.status && result.status === 'responded') {
          setIsDone(true);
        }
        setResponderData(result);
      } else {
        setNotFound(true);
      }
      setIsLoading(false);
    }
    call();
    setStartingTime(Date.now());
  }, [get, id]);

  const [formData, setFormData] = useState<ResponseFormData>({
    knowledge: null,
    knowledgeSharing: null,
    influence: null,
    collaboration: null,
    recommendation: '',
    message: '',
    giveRecommendation: 0,
    duration: -1,
    recommendationName: '',
    recommendationTitle: '',
  });

  const validateData = ({ knowledge, knowledgeSharing, influence, collaboration, }: ResponseFormData): ValidationResult => {
    const errors = [];

    const isValidScore = (score: number | null | undefined) => (typeof score === 'number' && score >= 0 && score <= 10);

    if (!isValidScore(knowledge)) { errors.push({ name: 'knowledge', message: t('allQuesstionsRequired') }) }
    if (!isValidScore(knowledgeSharing)) { errors.push({ name: 'knowledgeSharing', message: t('allQuesstionsRequired') }) }
    if (!isValidScore(influence)) { errors.push({ name: 'influence', message: t('allQuesstionsRequired') }) }
    if (!isValidScore(collaboration)) { errors.push({ name: 'collaboration', message: t('allQuesstionsRequired') }) }

    return { errors: errors.length > 0 ? errors : undefined, success: errors.length === 0 };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value,
    });
  };

  const handleAnswer = (question: string, value: string) => {
    setErrors(errors.filter(e => e.name !== question));
    setFormData({
      ...formData,
      [question]: parseInt(value),
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateData(formData);

    if (validationResult.success) {
      const duration = Math.round((Date.now() - startingTime) / 1000);

      const result = await post(`/respond/${id}`, {
        ...formData,
        duration
      });

      if (!result) {
        setErrors([{ name: 'form', message: t('serverError') }]);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else {
        setIsDone(true);
      }
    } else {
      setErrors(validationResult.errors || []);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  if (isLoading) {
    return <SystemMessage message="loading" />
  }

  if (notFound || !responderData) {    
    return <SystemMessage isError message="notFound" />
  }

  if (responderData.endDate < (new Date()).toISOString().substring(0, 10)) {
    return <SystemMessage isError message="surveyEnded" />
  }

  if (isDone) {
    return (
      <Section>
        <Box sx={{ mt: 64, borderRadius: '20px', backgroundColor: 'primary', mx: 64, pt: 24, px: 48, pb: 48 }}>
          <H1 sx={{ textAlign: 'center' }}>{t('thankYouHeading')}</H1>
          <Box sx={{ fontSize: 24, lineHeight: 1.6 }} as="p">{t('receiptText', responderData?.candidate)}</Box>
          
          <Box sx={{ textAlign: 'center', mt: 48 }}><Link href='https://feedbach.me'><Logo height="75" width="202" fillColor="#212" /></Link></Box>
        </Box>
      </Section>
    );
  }

  return (
    <Section>
      <H1 sx={{ textAlign: 'center', fontSize: 34 }}>{t('title')}<br />{responderData?.candidate}</H1>
      {responderData?.invitationText && (
        <>
          <H2>{t('invitationHeading')}</H2>
          <Box as="p" sx={{ whiteSpace: 'pre-wrap' }}>
            {responderData?.invitationText}
          </Box>
        </>
      )}
      <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
        {errors.filter(e => e.name !== 'form').length > 0 && <ErrorText sx={{ mt: 32, fontSize: 24, }} text={t('formErrors')} />}
        <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />
        <Answer
          error={errors.find(e => e.name === 'knowledge')}
          question="knowledge"
          onChange={handleAnswer}
          value={formData.knowledge}
          candidate={responderData?.candidate} />

        <Answer
          error={errors.find(e => e.name === 'knowledgeSharing')}
          question="knowledgeSharing"
          onChange={handleAnswer}
          value={formData.knowledgeSharing}
          candidate={responderData?.candidate} />

        <Answer
          error={errors.find(e => e.name === 'influence')}
          question="influence"
          onChange={handleAnswer}
          value={formData.influence}
          candidate={responderData?.candidate} />

        <Answer
          error={errors.find(e => e.name === 'collaboration')}
          question="collaboration"
          onChange={handleAnswer}
          value={formData.collaboration}
          candidate={responderData?.candidate} />

        <Input
          type="textarea"
          name="message"
          handleChange={handleChange}
          label={t('messageLabel')}
          value={formData.message} />

        <Input
          type="checkbox"
          error={errors.find(e => e.name === 'giveRecommendation')}
          name="giveRecommendation"
          handleChange={handleChange}
          label={t('giveRecommendation', responderData?.candidate)}
          value={formData.giveRecommendation}
          infoText={t('recommendationInfo', responderData?.candidate)} />

        {!!formData.giveRecommendation && <>
          <Input
            type="textarea"
            name="recommendation"
            handleChange={handleChange}
            label={t('recommendationLabel')}
            value={formData.recommendation} />

          <Input
            infoText={t('recommendationNameInfo')}
            name="recommendationName"
            handleChange={handleChange}
            label={t('recommendationName')}
            value={formData.recommendationName} />

          <Input
            name="recommendationTitle"
            handleChange={handleChange}
            label={t('recommendationTitle')}
            value={formData.recommendationTitle} />
        </>}

        <Button sx={{ color: 'mainText', backgroundColor: 'secondaryBackground', mx: 'auto', display: 'block', width: ['90%', '200px'] }}>{t('submit')}</Button>
      </Box>
    </Section>
  );
};

export default ResponseForm;
