import CopyValue from "../../../components/CopyValue";
import { H2 } from "../../../components/Headers";
import { Box } from "theme-ui";
import { useOrganizations } from "../../../contexts/UserProvider";
import { useTranslations } from "../../../contexts/Translation";


const InviteSection = () => {
  const { currentOrganization } = useOrganizations();
  const t = useTranslations('userManagementForm');

  return (
    <Box>
      <H2>{t('inviteUsersTitle')}</H2>
      <p>{t('inviteUsersDescription')}</p>
      <CopyValue value={`${process.env.REACT_APP_SELF}/join/${currentOrganization}`} />
    </Box>
  );
};

export default InviteSection;