import Section from "../Section";
import { useEffect, useState } from "react";
import { useApiData } from "../../contexts/ApiData";
import { H1 } from "../Headers";
import SystemMessage from '../SystemMessage/Index';
import EmScore from "../Survey/EmScore";
import { Department, Stats } from "../../types";
import { useTranslations } from "../../contexts/Translation";
import { Box, Flex, Grid } from "theme-ui";
import Input, { Select } from "../Forms/Input";
import { InfoMessageBox } from "../InfoMessage/InfoMessageBox";
import { InfoItem } from "../Survey/InfoItem";
import { sub, addDays } from 'date-fns';
import Button from "../Button";
import Answer from "../Survey/Answer";
import { CreateImprovementGoalLink } from "./CreateImprovementGoalLink";
import ImprovementGoals from "../ImprovementGoal/List";
import { useOrganizations, useRole } from '../../contexts/UserProvider';

const Dashboard = () => {
  const { get } = useApiData();
  const { currentOrganization, organizations } = useOrganizations();
  const [ stats, setStats ] = useState<Stats | null>(null);
  const [ periodValue, setPeriod ] = useState('forever');
  const [ from, setFrom ] = useState(sub(new Date(), { months: 6 }).toISOString().substring(0, 10));
  const [ to, setTo ] = useState((new Date()).toISOString().substring(0, 10));
  const t = useTranslations('orgDash');
  const role = useRole();

  const [ departments, setDepartments] = useState<Department[]>([]);
  const [ department, setDepartment] = useState<string>('');
  const [ isLoadingDepartments, setIsLoadingDepartments ] = useState(true);

  const getDateInterval = () => {
    const now = new Date();
    let fromDate = '2000-01-01', toDate = addDays(now, 365).toISOString().substring(0, 10);
    if (periodValue === 'interval') {
      fromDate = from;
      toDate = to;
    }
    if (periodValue === '12months') {
      fromDate = sub(now, { years: 1 }).toISOString().substring(0, 10);
    }
    if (periodValue === '6months') {
      fromDate = sub(now, { months: 6 }).toISOString().substring(0, 10);
    }
    if (periodValue === '3months') {
      fromDate = sub(now, { months: 3 }).toISOString().substring(0, 10);
    }
    // default: periodValue === 'forever') {
    
    return { fromDate, toDate };
  };

  const getStats = async () => {
    const { fromDate, toDate } = getDateInterval();
    const response = await get(`/organization/stats?fromDate=${fromDate}&toDate=${toDate}&department=${department}`);
    setStats(response);
  };

  useEffect(() => {
    getStats();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const call = async () => {
      const response = await get(`/organization/${currentOrganization}/departments`);
      setIsLoadingDepartments(false);
      if (response) {
        const orgName = organizations && organizations[0] ? organizations[0].name : '';
        setDepartments([{ id: null, name: orgName }, ...response]);
        setDepartment('');
      }      
    };
    call();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!stats) {
    return <SystemMessage message="loading" />
  }
  if (isLoadingDepartments) {
    return <SystemMessage message="loading" />
  }

  return (
    <Section>
      <H1>{t('scoreHeading')}</H1>
      <Grid sx={{ gridTemplateColumns: 'repeat(5, 1fr)', columnGap: 8 }}>
        <Select
          csx={{ mt: 0 }}
          options={[
            { value: 'forever', text: t('periodForever') },
            { value: '12months', text: t('periodYear') },
            { value: '6months', text: t('period6m') },
            { value: '3months', text: t('period3m') },
            { value: 'interval', text: t('periodInterval') }
          ]}
          handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => setPeriod(e.target.value)}
          name="period"
          label={t('periodLabel')}
          value={periodValue}
        />
        { periodValue === 'interval' && 
          <>
            <Input
              csx={{ mt: 0 }}
              type="date"
              name="from"
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setFrom(e.target.value)}
              label={t('fromDate')}
              value={from} />
            <Input
              csx={{ mt: 0 }}
              type="date"
              name="to"
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setTo(e.target.value)}
              label={t('toDate')}
              value={to} />
          </>
        }
        { departments.length > 0 && (
          <Select
            csx={{ mt: 0 }}
            options={departments.map(d => ({ value: d.id || '', text: d.name }))}
            handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => setDepartment(e.target.value)}
            name="department"
            label={t('departmentLabel')}
            value={department}
          />
        )}
        <Button sx={{ height: '3em', mt: '1.5em' }} onClick={() => getStats()}>{t('updateBtn')}</Button>
      </Grid>
      <Flex sx={{ mt: '0.75em', mb: '0.5em', justifyContent: 'space-between', fontSize: 36 }}>
        <Box sx={{ ml: '1em', display: 'flex', justifyContent: 'center', }}>
          <EmScore inside={t('card.totalScoreLabel')} score={stats?.total?.totalScore} size={6} sx={{ width: '6em' }} />
        </Box>
        <Box sx={{ fontSize: 18, width: '24em' }}>
          <InfoItem label={t('answersLabel')} value={`${stats?.total?.answers}`} />
          <InfoItem label={t('surveyLabel')} value={`${stats?.total?.surveys}`} />
          <InfoMessageBox body={t('orgScoreInfo')}></InfoMessageBox>
        </Box>
      </Flex>
      <Box>
      {['knowledge', 'knowledgeSharing', 'influence', 'collaboration'].map(question => (
        <Answer
          key={question}
          question={question}
          candidate={t('candidatePlaceholder')}
          score={stats.questions.find(q => q.question === question)?.score}
          answers={stats.questions.find(q => q.question === question)?.count}
        />))}
      </Box>

      { ['supporter', 'owner'].includes(`${role}`) && <CreateImprovementGoalLink from={from} /> }
      <ImprovementGoals isOrgGoals />
    </Section>
  );
};

export default Dashboard;
