
import { Box } from 'theme-ui';
import { useLocale, useTranslations } from '../../contexts/Translation';
import { H2, H3 } from '../Headers';
import Section from '../Section';
import { useEffect, useState } from 'react';
import { useApiData } from '../../contexts/ApiData';
import { v4 as uuid } from 'uuid';
import Button from '../Button';
import Input, { Select } from './Input';
import { Profile, Survey, ValidationError, ValidationResult } from '../../types';
import ErrorText from './ErrorText';
import { useNavigate } from 'react-router-dom';
import { useSurvey } from '../../contexts/Survey';
import { useOrganizations, useProfile, useRole } from '../../contexts/UserProvider';
import LanguageSelector from '../LanguageSelector/Index';
import GroupAccess from './GroupAccess';

type SurveyFormData = {
  id: string;
  endDate: string;
  invitationText: string;
  surveyName: string;
  defaultLang: string;
  accessibleBy: string;
  onBehalfOf: string | null;
  groupsWithAccess?: string[];
};

type SurveyFormProps = {
  isEdit?: boolean
}

const SurveyForm = ({ isEdit = false }: SurveyFormProps) => {
  const t = useTranslations('surveyForm');
  const role = useRole();
  const profile = useProfile();
  const locale = useLocale();
  const { post, put, get } = useApiData();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [members, setMemebers] = useState<Profile[]>([]);
  const navigate = useNavigate();
  const survey: Survey | null = useSurvey();
  const initialData: SurveyFormData = {
    id: survey?.id || uuid(),
    endDate: survey?.endDate || (new Date(Date.now() + 7*24*3600000)).toISOString().substring(0,10),
    invitationText: survey?.invitationText || '',
    surveyName: survey?.surveyName || '',
    defaultLang: survey?.defaultLang || locale || '',
    accessibleBy: survey?.accessibleBy || 'me',
    onBehalfOf: survey?.onBehalfOf || profile?.userId || '',
  };
  const [formData, setFormData] = useState<SurveyFormData>(initialData);
  const isOnbehalfOf = formData.onBehalfOf !== null && formData.onBehalfOf !== profile?.userId;
  const { currentOrganization, currentRole } = useOrganizations();

  useEffect(() => {
    const getUsers = async () => {
      const members = await get('/users');
      if (members) {
        setMemebers(members);
      }
    }
    if (role === 'supporter' || role === 'owner') {
      getUsers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role])

  const validateData = ({ endDate, }: SurveyFormData): ValidationResult => {
    const errors = [];
    const inOneWeek = (new Date(Date.now() + 7*24*3600000)).toISOString().substring(0, 10);

    if (!endDate) { errors.push({ name: 'endDate', message: t('fieldRequired')}) }
    if (!isEdit && endDate < inOneWeek) {  errors.push({ name: 'endDate', message: t('minimumDuration')}) }

    return { errors: errors.length > 0 ? errors: undefined, success: errors.length === 0 };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setErrors(errors.filter(err => err.name !== e.target.name));
    setFormData({
      ...formData,
      accessibleBy: e.target.name === 'onBehalfOf' && e.target.value !== profile?.userId ? 'supporters' : e.target.value,
      groupsWithAccess: e.target.name === 'accessibleBy' && e.target.value !== 'selectedGroups' ? [] : formData.groupsWithAccess,
      [e.target.name]: e.target.value,
    });
  };

  const setGroupsWithAccess = (groups: string[]) => {
    setFormData({ ...formData, groupsWithAccess: groups })
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateData(formData);

    if (validationResult.success) {
      const result = isEdit ? await put(`/survey/${formData.id}`, formData) : await post(`/survey/${formData.id}`, formData);
      if (!result) {
        setErrors([{ name: 'form', message: t('serverError') }]);
      } else {
        if (isEdit) {
          window.location.reload();
        } else {
          navigate(`/survey/${result.id}`)
        }
      }
    } else {
      setErrors(validationResult.errors || []);
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth',
      }); 
    }
  };
  const getAccessibleByOptions = () => {
    if (['supporter', 'owner'].includes(currentRole) && isOnbehalfOf) {
      return [
        { value: 'supporters', text: t('accessibleBySupporters')},
        { value: 'selectedGroups', text: t('accessibleBySelectedGroups')},
      ];
    }
    return [
      { value: 'me', text: t('accessibleByMe')},
      { value: 'supporters', text: t('accessibleBySupporters')},
      { value: 'selectedGroups', text: t('accessibleBySelectedGroups')},
      { value: 'org', text: t('accessibleByOrganization')},
    ];
  }

  return (
    <Section>
      <H2>{isEdit ? t('editHeading') : t('heading')}</H2>
      <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      
      { errors.filter(e => e.name !== 'form').length > 0 && <ErrorText sx={{ mt: 32, fontSize: 24, }} text={t('formErrors')} /> }
      <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />

      <Input
        error={errors.find(e => e.name === 'surveyName')}
        name="surveyName"
        handleChange={handleChange}
        label={t('surveyName')}
        value={formData.surveyName} />
      { (role === 'supporter' || role === 'owner') && (
        <Select
          options={members.map(m => ({ value: `${m.userId}`, text: `${m.candidate}` }))}
          handleChange={handleChange}
          name="onBehalfOf"
          label={t('onBehalfOf')}
          value={formData.onBehalfOf || ''}
        />
      )}
      <Input
        error={errors.find(e => e.name === 'endDate')}
        type="date"
        name="endDate"
        handleChange={handleChange}
        label={t('endDate')}
        value={formData.endDate} />
      
      <Box sx={{ my: 16 }}>
        <LanguageSelector
          handleChange={handleChange}
          name="defaultLang"
          label={t('defaultLang')}
          value={formData.defaultLang}
        />
      </Box>

      <Input type="textarea" name="invitationText" handleChange={handleChange} label={t('invitationText')} value={formData.invitationText} />

      <Box sx={{ my: 16 }}>
        <H3>{t('accessHeading')}</H3>
        <Select
          options={getAccessibleByOptions()}
          readOnly={!currentOrganization}
          handleChange={handleChange}
          name="accessibleBy"
          label={t('accessibleBy')}
          value={formData.accessibleBy}
        />
        { formData.accessibleBy === 'selectedGroups' && <GroupAccess initiallySelected={survey?.groupsWithAccess || []} setGroupsWithAccess={setGroupsWithAccess} /> }
      </Box>

      <Button>{t(isEdit ? 'submitEdit' : 'submit')}</Button>
    </Box>
    </Section>
  );
};

export default SurveyForm;
