import { ReactNode } from "react";
import { ThemeUIStyleObject } from "theme-ui";

export interface WithSx {
  sx?: ThemeUIStyleObject | undefined;
}

export interface WithChildren {
  children: React.ReactNode;
}

export type EmailLang = {
  email: string;
  lang: string;
};

export type BaseThemeCompProp = WithSx & WithChildren & {
  onClick?: () => void;
  disabled?: boolean;
};

export interface Organization {
  id: string;
  name: string;
  orgNo: string;
  domain: string;
  logo: string;
  userId: string;
  registered: string;
}

export enum SubscriptionType {
  Trial = 'trial',
  Recurring = 'recurring',
  None = 'none'
}

export enum SubscriptionStatus {
  Active = 'active',
  Ended = 'ended',
  Created = 'created',
  None = 'none'
}

export type SubscriptionData = {
  id?: string;
  type: SubscriptionType;
  status: SubscriptionStatus;
  startDate?: string;
  endDate?: string;
}

export interface Subscription {
  id: string;
  organizationId: string;
  paymentId?: string;
  type: SubscriptionType;
  startDate: string;
  endDate: string;
  status: SubscriptionStatus;
  updatedAt: string;
}

export interface Payment {
  id: string;
  currency: string;
  amount: number;
  paymentDate: string;
}

export interface Survey {
  id: string;
  userId: string;
  surveyName: string;
  organization: string;
  candidate: string;
  startDate: Date;
  endDate: string;
  defaultLang: string;
  registered?: Date;
  status?: string;
  respondents?: number;
  changed: Date;
  invited?: number;
  score?: number;
  invitationText?: string;
  accessibleBy: string;
  reflections?: string;
  isShared?: boolean;
  onBehalfOf: string | null;
  groupsWithAccess: string[];
}

export interface Answer {
  // id: string;
  question: string;
  value: number;
}

export type OrgRole = {
  orgId: string;
  role: string;
}

export type Profile = {
  userId: string;
  image?: string;
  candidate?: string;
  title?: string;
  roles: OrgRole[];
  consentToTerms: number;
}

export interface Response {
  // id: string;
  surveyId: string;
  responder: string;
  answers: Answer[];
  status: string;
  recommendation?: string | null;
  recommendationName?: string;
  recommendationTitle?: string;
  message?: string | null;
}

export interface Recommendation {
  surveyId: string;
  respondedAt: string;
  email: string;
  recommendation?: string | null;
  recommendationName?: string;
  recommendationTitle?: string;
}

export interface Responder {
  id: string;
  email: string;
  lang: string;
  surveyId: string;
  status: string; // new, emailed, responded
  invitedBy: string;
  registered: string;
}

export interface ResponderData {
  id: string;
  surveyId: string;
  candidate: string;
  status: string;
  invitationText: string;
  endDate: string;
}

export type ValidationError = {
  name?: string;
  message?: string;
};

export type ValidationResult = {
  success?: boolean,
  errors?: ValidationError[],
};

type SelectOptions = {
  text: string;
  value: string;
};

export type InputProps = WithSx & {
  name: string;
  value?: string | number | undefined | boolean;
  type?: string;
  label?: string;
  readOnly?: boolean;
  options?: SelectOptions[],
  infoText?: string | ReactNode;
  handleChange?: React.ChangeEventHandler;
  handleBlur?: React.ChangeEventHandler;
  error?: ValidationError | null,
  csx?: ThemeUIStyleObject | undefined,
};

export type WithInputProps = InputProps & {};

export type Card = {
  cardId: string;
  userId: string;
  fromSurvey: string;
  toSurvey: string;
  responders: number;
  totalScore: number;
  latestResponse: string;
  knowledge: number;
  knowledgeSharing: number;
  influence: number;
  collaboration: number;
  surveyIds: string;
};

export type SettingsFormProps = {
  isEdit?: boolean,
}

export type OrgUser = {
  candidate: string;
  email: string;
  userId: string;
  role: string;
  orgId: string;
  department?: string;
}

export type ScoresObject = {
  knowledge: number;
  knowledgeSharing: number;
  influence: number;
  collaboration: number;
}

export type OrgGoal = {
  id: string;
  name: string;
  categories: string[];
  status?: string;
  goal: string;
  situation: string;
  plan: string;
  result: string;
  endDate: string;
  registered?: string;
  userId?: string;
  scoreData?: ScoresObject;
  isSuccess?: string;
  originalFrom: string;
  originalTo: string;
};

export type ImprovementGoal = {
  id: string;
  originalSurvey: string;
  name: string;
  categories: string[];
  resultSurvey?: string;
  status?: string;
  goal: string;
  situation: string;
  plan: string;
  result: string;
  endDate: string;
  registered?: string;
  accessibleBy: string;
  userId?: string;
  isSuccess?: string;
  scoreData?: ScoresObject;
  sharedBy?: string;
  candidate?: string;
  groupsWithAccess: string[];
};

export type ImprovementSurvey = {
  id: string;
  surveyName: string;
  candidate: string;
  startDate: string;
  endDate: string;
  status: string;
  answers: ScoreResult[];
};

export type ImprovementData = ScoresObject & {
  surveyName?: string;
  startDate: string;
  endDate: string;
  accessibleBy: string;
  answers?: ScoreResult[];
}

export type ScoreResult = {
  question: string;
  score: number;
  count?: number;
};

export type GoalContext = {
  goal?: ImprovementGoal | null;
  improvementData?: ImprovementData | null;
  recentSurveys?: ImprovementSurvey[] | null;
  originalSurvey?: Survey | null;
}

export type ConclusionData = {
  id: string;
  result: string;
  isSuccess: string;
}

export type Comment = {
  id: string;
  surveyId?: string | null;
  goalId?: string | null;
  content: string;
  userId?: string;
  name?: string;
  registered?: string;
};

type Total = {
  answers: number;
  surveys: number;
  startTime: string;
  endTime: string;
  totalScore: number;
}

export type Stats = {
  questions: ScoreResult[],
  total: Total;
}

export type Department = {
  id?: string;
  name: string;
  org: string;
};

export type Group = {
  id: string;
  name: string;
  org: string;
};