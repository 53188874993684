
const emailStyle = `
<style>
  .email-container {
    display: block;
    margin: 0 auto;
    width: 320px;
    color: #441144;
    font-size: 18px;
    background-color: #EDCDFF;
    border-radius: 10px;
    padding: 20px;
  }

  .cta {
    display: block;
    width: 75%;
    margin: 24px auto;
    padding: 10px 20px;
    text-align: center;
    color: #441144;
    background-color: #FFF7D0;
    border-radius: 5px;
    text-decoration: none;
  }
  .invitationMessage {
    font-style: italic;
    border-top: 1px solid #441144;
    border-bottom: 1px solid #441144;
    padding: 24px 16px;
    white-space: pre-wrap;
  }
  p {
    margin: 10px 0;
  }
  a {
    color: #441144;
  }
  .readMore {
    font-size: 14px;
    margin-top: 24px;
  }
</style>
`;

const emailContent = {
  en: `
    ${emailStyle}
    <div class="email-container">
      <div class="email-content">
        <p><strong>{1}</strong> has invited you to give feedback using feedbach.me</p>
        <p class="invitationMessage">{2}</p>
        <p style="margin-top: 20px">It takes less than a minute to answer, and it will be of great help for {1}! Maybe it also will strengthen 
        the deliveries and the relationship between you?</p>
        <div><a class="cta" href="{3}" class="email-button">Go to the survey</a></div>
        <p class="readMore">If you want to know more about Feedbach, you can read <a target="_blank" href="https://www.feedbach.me/feedbach-for-clients">our article explaining how Feedbach 
        is valuable</a> for you as a client or colleague of {1}.</p>
      </div>
    </div>
`,
no: `
      ${emailStyle}
      <div class="email-container">
        <div class="email-content">
        <p><strong>{1}</strong> har invitert deg til å gi tilbakemelding gjennom feedbach.me</p>
        <p class="invitationMessage">{2}</p>
        <p style="margin-top: 20px">Det tar under ett minutt å svare, og det er til stor hjelp for {1}! Kanskje det også styrker 
        leveransen og relasjonen mellom dere?</p>
        <div><a class="cta" href="{3}">Gå til besvarelsen</a></div>
        <p class="readMore">Hvis du vil vite mer om Feedbach kan du lese <a target="_blank" href="https://www.feedbach.me/feedbach-for-clients">vår artikkel som forklarer hvordan Feedbach er verdifullt</a> for deg som en kunde eller kollega av {1}.</p>
        </div>
      </div>
    `
}

const getEmailContent = (name: string, lang: 'en' | 'no', invitationText: string) => {
  return emailContent[lang].replace('{1}', name).replace('{2}', invitationText).replace('{3}', 'https://www.feedbach.me');
};



export const EmailPreview = ({ name, invitationText, lang}: { name: string; invitationText: string; lang: 'en' | 'no'; }) => {

  const emailContent = getEmailContent(name, lang, invitationText);

  return (
    <div dangerouslySetInnerHTML={{ __html: emailContent }} />
    
  );
};