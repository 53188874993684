import { useImprovementGoal } from "../../contexts/ImprovementGoal";
import { ConclusionPhaseComponent } from "./ConclusionPhaseComponent";

const ConclusionPhase = ({ isEdit = false }) => {
  const { goal, improvementData, recentSurveys } = useImprovementGoal();
  
  if (!goal || !recentSurveys) {
    return null;
  }
  return (
      <ConclusionPhaseComponent goal={goal} isEdit={isEdit} improvementData={improvementData} recentSurveys={recentSurveys} />
  );
};

export default ConclusionPhase;
